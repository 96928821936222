import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StatusMessages, { useMessages } from "./StatusMessages";
import stripeWeb from "./stripeweb.js";

const ApplePay = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [messages, addMessage] = useMessages();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const costvalue = 1242;
    const stripemsg = JSON.stringify({ cost: costvalue, currency: "gbp" });

    const pr = stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "Demo Pay",
        amount: costvalue,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        // const stripemsg = JSON.stringify({ cost: 1239, currency: "gbp" });
        const aa = stripeWeb(stripemsg, "/cpi.php")
          .then((res) => {
            console.log(res);
            setClientSecret(res.clientSecret);
            setPaymentRequest(pr);
            //
            aptag(res.clientSecret);
            //
          })
          .catch((err) => console.log(err));
      }
    });

    function aptag(clientSecret) {
      pr.on("paymentmethod", async (e) => {
        console.log(e);

        /*const { error: backendError, clientSecret } = await fetch(
          "https://amihud.com/stripe_payment/create-payment-intent.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              currency: "gbp",
              amount: "1111",
            }),
          }
        ).then((r) => r.json());

        if (backendError) {
          console.log(backendError.message);
          addMessage(backendError.message);
          return;
        }*/

        //  addMessage("Client secret returned:" + clientSecret);

        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            { handleActions: false }
          );

        if (stripeError) {
          // Show error to your customer (e.g., insufficient funds)
          addMessage(stripeError.message);
          e.complete("faile");
          return;
        }

        /*  if (paymentIntent.status == "requires_action") {
          addMessage("requires_action");
          stripe.confirmAffirmPayment(clientSecret);
        } else {
          setTimeout(function () {
            e.complete("success");
          }, 22000);
        }*/

        //////
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          addMessage("requires_action");
          let { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret
          );
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            addMessage(error.message);
            return;
          }
          addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
        } else {
          setTimeout(function () {
            e.complete("success");
          }, 22000);
        }

        //////

        // js timer 22 secomds
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      });
    }
    /*   pr.on("paymentmethod", async (e) => {
      console.log(e);

      const { error: backendError, clientSecret } = await fetch(
        "https://amihud.com/stripe_payment/create-payment-intent.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: "gbp",
            amount: "1111",
          }),
        }
      ).then((r) => r.json());

      if (backendError) {
        console.log(backendError.message);
        addMessage(backendError.message);
        return;
      }

      //  addMessage("Client secret returned:" + clientSecret);

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        e.complete("faile");
        return;
      }

    

      //////
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        addMessage("requires_action");
        let { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret
        );
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          addMessage(error.message);
          return;
        }
        addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      } else {
        setTimeout(function () {
          e.complete("success");
        }, 22000);
      }

      //////

      // js timer 22 secomds
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });*/
  }, [stripe, elements, addMessage]);

  return (
    <>
      <h1>Apple Pay</h1>

      <p>
        Before you start, you need to:
        <ul>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-testing"
              target="_blank"
            >
              Add a payment method to your browser.
            </a>{" "}
            For example, add a card to your Wallet for Safari.
          </li>
          <li>
            Serve your application over HTTPS. This is a requirement both in
            development and in production. One way to get up and running is to
            use a service like{" "}
            <a
              href="https://ngrok.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ngrok
            </a>
            .
          </li>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#verifying-your-domain-with-apple-pay"
              target="_blank"
            >
              Verify your domain with Apple Pay
            </a>
            , both in development and production.
          </li>
        </ul>
      </p>

      <a
        href="https://stripe.com/docs/stripe-js/elements/payment-request-button"
        target="_blank"
      >
        Stripe Documentation
      </a>

      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}

      <StatusMessages messages={messages} />

      <p>
        {" "}
        <a href="https://youtu.be/bMCsJfJyQKA" target="_blank">
          Watch a demo walkthrough
        </a>{" "}
      </p>
    </>
  );
};

export default ApplePay;
