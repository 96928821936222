// helper.js
import axios from "axios";
//const axios = require("axios");

// CORS enabled apikey

// Autotrade dela

export const stripeWeb = (body, prefix = "index.php") => {
  const url = "https://" + "amihud.com" + "/stripe_payment";

  // console.log("url:"+url);

  const jwt = localStorage.getItem("jwt");
  if (jwt) body.token = jwt;

  // console.log(JSON.stringify(body), `Sending body  ${url}/${path}/${prefix}`);
  return axios
    .post(`${url}/${prefix}`, body)
    .then(({ data }) => data)
    .catch((err) => console.log(err), "API error info");
};
export default stripeWeb;
