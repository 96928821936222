import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Index page with list of payment methods.
import List from "./List";

// Payment method components.

import ApplePay from "./ApplePay";

import GooglePay from "./GooglePay";

import "./App.css";

function App(props) {
  return (
    <>
      <ApplePay />
      {/*<a href="/apple-pay">home</a>*/}
      <BrowserRouter>
        <Routes>
          <Route path="/apple-pay" element={<ApplePay />} />

          <Route path="/google-pay" element={<GooglePay />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
